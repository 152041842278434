import React, { useEffect } from "react";
import FeatureCard from "../components/FeatureCard";
import Logo from "../images/Hyloq/Main-Logo.svg";
import DrawFence from "../images/Hyloq/Draw-fence.png";
import HomeHero from "../images/Hyloq/HomeSlider_BG.jpg";
import HyloqUser2 from "../images/Hyloq/hyloq-user-2.png";
import HeroMap from "../images/Hyloq/Hero-Map.png";
import MarkerRadiate from "../images/Hyloq/Marker-Radiate.svg";
import HomeGroupMap from "../images/Hyloq/Home-Group-Map.png";
import { Helmet } from "react-helmet";

const hyloq = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // useEffect(() => {
  //   window.location.href = `/`;
  // }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Hyloq Geofencing Services | Geofence Marketing & Advertising
        </title>

        <meta
          name="description"
          content="Hyloq is a premier geofencing advertising and marketing platform. Programmatic advertising is now hyperlocal. "
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <meta
          property="og:description"
          content="Consult FGC is a boutique digital marketing, SEO, web design, &amp; web development company. We offer an experienced but personal touch to help meet your digital branding needs &amp; grow your business."
        ></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Consult FGC is a boutique digital marketing, SEO, web design, &amp; web development company. We offer an experienced but personal touch to help meet your digital branding needs &amp; grow your business."
        ></meta>
        <meta
          name="twitter:title"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>

      <div class="desktop hidden md:block">
        <div class="hero relative bg-black overflow-hidden">
          <div class="max-w-7xl mx-auto pt-5 md:pt-0 pb-10 md:pb-0">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover"
                src={HomeHero}
                alt="Consult Home"
              />
            </div>
            <div class="z-20 uppercase relative nav pb-16">
              <div class="pt-5 relative ">
                <nav
                  class="relative flex items-center justify-between sm:h-10"
                  aria-label="Global"
                >
                  <div class="">
                    <a href="/">
                      <img class="h-12 w-auto" src={Logo} />
                    </a>
                  </div>

                  <div class="mr-32">
                    <a
                      href="tel:8135979187"
                      class="ml-14 font-medium text-white hover:underline"
                    >
                      Call Us +1 (813) 597.9187
                    </a>
                  </div>
                </nav>
              </div>
            </div>

            <div class="text-center relative z-10 pb-8 sm:pb-16 md:pb-36 lg:max-w-7xl lg:w-full ">
              <main class="flex justify-between mt-10 mx-auto max-w-7xl">
                <div class="sm:text-center lg:text-left">
                  <h1 class="uppercase text-4xl tracking-normal font-bold text-white sm:text-5xl md:text-7xl">
                    Hyper Simplicity
                  </h1>
                  <p class="uppercase mt-1 text-base font-light text-white sm:text-lg sm:max-w-xl sm:mx-auto md:text-5xl lg:mx-0">
                    Stand out in the noise
                  </p>
                  <p class="px-5 md:px-0 text-center md:text-left mt-3 text-base text-white sm:mt-5 sm:text-lg max-w-2xl mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Unleash the Power of Precision Marketing with Our Advanced
                    Geofencing Software!🌍🎯
                  </p>
                  <p class="px-5 md:px-0 text-center md:text-left mt-3 text-base text-white sm:mt-5 sm:text-lg max-w-2xl mx-auto md:mt-5 md:text-lg lg:mx-0">
                    Welcome to the future of marketing! Our cutting-edge
                    geofencing software is meticulously crafted for hyperlocal
                    marketing mastery. Imagine reaching your audience with
                    pinpoint accuracy, right when it matters the most. Our
                    advanced technology lets you draw virtual boundaries around
                    specific areas, allowing you to capture devices and engage
                    potential customers.
                  </p>
                  <p class="font-bold underline px-5 md:px-0 text-center md:text-left mt-3 text-base text-white sm:mt-5 sm:text-lg max-w-2xl mx-auto md:mt-5 md:text-lg lg:mx-0">
                    Hyloq allows for:
                  </p>
                  <div class="relative pl-9 max-w-2xl mx-auto md:mt-5 md:text-base lg:mx-0">
                    <div
                      class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      🚀
                    </div>

                    <dt class="inline font-semibold text-white">
                      Hyperlocal Precision:{" "}
                    </dt>
                    <p class="inline text-white">
                      Reach customers around your business location, or multiple
                      locations, ensuring your message hits the right audience.
                    </p>
                  </div>
                  <div class="relative pl-9 max-w-2xl mx-auto md:mt-5 md:text-base lg:mx-0">
                    <div
                      class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      🎯
                    </div>

                    <dt class="inline font-semibold text-white">
                      Targeted Campaigns:{" "}
                    </dt>
                    <p class="inline text-white">
                      Craft personalized marketing campaigns based on customer
                      behavior, location, and preferences, ensuring every
                      message resonates.
                    </p>
                  </div>
                  <div class="relative pl-9 max-w-2xl mx-auto md:mt-5 md:text-base lg:mx-0">
                    <div
                      class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      📈
                    </div>

                    <dt class="inline font-semibold text-white">
                      Maximized ROI:{" "}
                    </dt>
                    <p class="inline text-white">
                      Optimize your marketing budget by focusing on the audience
                      that matters most, driving higher conversion rates and
                      maximizing your return on investment.
                    </p>
                  </div>
                  <div class="relative pl-9 max-w-2xl mx-auto md:mt-5 md:text-base lg:mx-0">
                    <div
                      class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      💡
                    </div>

                    <dt class="inline font-semibold text-white">
                      In-Depth Analytics:{" "}
                    </dt>
                    <p class="inline text-white">
                      Gain valuable insights with detailed analytics, track
                      customer interactions, and adapt your strategies for
                      unparalleled marketing intelligence.
                    </p>
                  </div>

                  <div class="relative pl-9 max-w-2xl mx-auto md:mt-5 md:text-base lg:mx-0">
                    <div
                      class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      🌐
                    </div>

                    <dt class="inline font-semibold text-white">
                      Seamless Integration:{" "}
                    </dt>
                    <p class="inline text-white">
                      Effortlessly integrate our geofencing software into your
                      existing marketing toolkit, ensuring a smooth and
                      streamlined experience.
                    </p>
                  </div>

                  <div class="hidden mt-14">
                    <a
                      href="/contact-us"
                      class="inline-flex items-center text-blue-900 bg-blue-100 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-blue-900 focus:outline-none hover:text-white"
                    >
                      Contact Sales
                    </a>
                  </div>
                </div>
                <div class="max-w-lg bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-sky-400 via-white p-10 rounded-lg shadow-2xl">
                  {" "}
                  <section id="form">
                    <h1 class="text-4xl tracking-normal font-semibold text-sky-500 sm:text-5xl">
                      Let Hyloq Help You Today
                    </h1>
                    <form
                      method="POST"
                      action="/thank-you"
                      target="_self"
                      id="Hyloq-page-form"
                      name="Hyloq-page-form"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      class=" lg:flex-auto grid-cols-2 grid gap-x-3"
                    >
                      <input
                        type="hidden"
                        name="form-name"
                        value="Hyloq-page-form"
                      />

                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autocomplete="given-name"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autocomplete="given-name"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="mt-6">
                          <input
                            id="job-title"
                            name="job-title"
                            type="text"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Job Title"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            name="company-name"
                            id="company-name"
                            autocomplete="family-name"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>

                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            id="company-website"
                            name="company-website"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Company Website"
                          />
                        </div>
                      </div>

                      <div>
                        <div class="mt-6">
                          <input
                            type="email"
                            name="biz-email"
                            id="biz-email"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Company Email"
                          />
                        </div>
                      </div>
                      <div class="sm:col-span-2">
                        <select
                          type="text"
                          name="Monthly-Budget"
                          id="Monthly-Budget"
                          class="mt-6 block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="" selected disabled hidden>
                            What’s your monthly ad spend?
                          </option>
                          <option value="$5,000 - $10,000">
                            $5,000 - $10,000
                          </option>
                          <option value="$10,000 - $25,000">
                            $10,000 - $25,000
                          </option>
                          <option value=" $25,000 - $50,000+">
                            $25,000 - $50,000+
                          </option>
                          <option value=" NOT SURE HELP ME">
                            NOT SURE HELP ME
                          </option>
                        </select>
                      </div>
                      <div class="sm:col-span-2">
                        <div class="mt-6">
                          <textarea
                            id="message"
                            name="message"
                            rows="4"
                            aria-describedby="message-description"
                            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="How can we help you?    Max 500 characters"
                          ></textarea>
                        </div>
                      </div>
                      <div class="mt-6 col-span-2">
                        <button
                          type="submit"
                          class="block w-full rounded-md bg-red-600 px-3.5 py-4 text-center text-sm font-semibold text-white hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Schedule A Free Consultation
                        </button>
                      </div>
                      <p class="col-span-2 px-10 mt-4 text-xs italic text-center leading-6 text-gray-500">
                        We do not sell your information and will not overwhelm
                        you with emails.
                      </p>
                    </form>
                  </section>
                </div>
              </main>
            </div>
          </div>
          <div class="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5">
            <img
              class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src={HeroMap}
              alt="Geofencing Map"
            />
          </div>
        </div>
        <div class="text-center relative isolate overflow-hidden bg-blue-900">
          <div class="px-6 pt-6 pb-12 sm:px-6 lg:px-8">
            <p class="mx-auto mt-6 max-w-7xl text-xl leading-8 text-gray-300">
              Don't miss out on the marketing revolution! Transform your
              outreach with our geofencing software and watch your business
              thrive in the age of hyperlocal marketing. Get started today and
              witness the power of precision marketing at your fingertips! 🔥✨
              #GeofencingMagic #HyperlocalMarketing #MarketingRevolution
            </p>
          </div>
        </div>
        <div class="bg-gradient-to-bl from-blue-100 via-gray-100 to-blue-200 get valuable data">
          <div class="relative overflow-hidden">
            <div class="max-w-7xl mx-auto">
              <div class="px-5 md:px-0 relative pb-8 sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full">
                <main class="mt-14 mx-auto max-w-7xl ">
                  <div class="sm:text-center lg:text-left">
                    <p class="uppercase text-3xl font-semibold text-gray-700 mx-auto md:mt-5 md:text-4xl lg:mx-0">
                      The Real POWER of Hyloq{" "}
                      <span class="block">is in the Pricing</span>
                    </p>
                    <p class="mt-3 pr-10 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-3xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Say goodbye to exorbitant hidden advertising costs. Hyloq
                      creates a direct line to multiple ad-exchanges, so you get
                      wholesale pricing for your ad campaigns, based on{" "}
                      <span class="underline">ACTUAL PRICE (*TRUE SPEND).</span>{" "}
                      Simply put, there is no upcharge or middleman between you
                      and the Ad Exchange running the campaign. Hyloq is your
                      direct portal to access TRUE SPEND ADVERTISING. With
                      Hyloq, you can run programmatic ads at a significantly
                      less rate than other agencies. We don’t hide or pass fees
                      onto you; this is your True-Spend™. Find out how we do it,
                      give us a call or fill out our form.
                    </p>
                    <div class="sm:mt-8 sm:flex sm:justify-center lg:justify-start items-center">
                      <img
                        class="hidden md:block w-1/5 "
                        src={MarkerRadiate}
                        alt="Hyloq ID"
                      />
                      <div class="px-10 md:ml-10 bg-white shadow shadow-lg overflow-hidden rounded-2xl">
                        <ul class="divide-y divide-gray-200">
                          <li class="text-2xl text-red-600 uppercase font-extrabold py-4">
                            Hyloq Id:{" "}
                            <span class="text-black">555-555-555</span>
                          </li>
                          <div class="flex justify-start">
                            <li class="inline py-4 text-gray-700">
                              Dwell:{" "}
                              <span class="text-gray-700 font-bold">
                                {" "}
                                30 mins
                              </span>{" "}
                            </li>
                            <li class="ml-5 py-4 text-gray-700">
                              Enter:{" "}
                              <span class="text-gray-700 font-bold">
                                {" "}
                                3:00 pm
                              </span>{" "}
                            </li>
                            <li class="ml-5 py-4 text-gray-700">
                              Leaves:{" "}
                              <span class="text-gray-700 font-bold">
                                {" "}
                                3:30 pm
                              </span>{" "}
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
            <div class="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3">
              <img class="" src={HomeGroupMap} alt="Geofencing Map" />
            </div>
          </div>

          <div className="pb-20 justify-center items-center flex flex-col lg:flex-row max-w-xl mx-auto lg:max-w-screen-xl">
            <div className="hidden md:flex md:flex-1">
              <img
                className="object-contain"
                src={HyloqUser2}
                alt="Hyloq User"
              />
            </div>

            <div className="flex px-4 pb-8 sm:pb-12 sm:px-6 lg:pb-16 flex-1">
              <div className="lg:ml-0 lg:pl-10">
                <h2 className="mt-8 lg:mt-0 text-3xl leading-9 font-semibold text-gray-700 sm:text-4xl sm:leading-10">
                  Reach your target audience easily and effectively
                </h2>
                <p className="mt-6 text-lg leading-7 text-gray-500">
                  Hyloq streamlines the process of creating geofences and
                  accessing a large inventory of ad networks to serve ads easily
                  and efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="Platform md:py-16 bg-white overflow-hidden">
          <div className="mt-12 md:mt-4 relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-semibold text-gray-700 tracking-tight  sm:text-4xl sm:leading-10">
                Campaigns
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-2xl leading-7 text-blue-800">
                Create, Manage, Evaluate, Repeat.
              </p>
              <p className="mt-4 max-w-3xl mx-auto text-center text-lg leading-7 text-gray-500">
                Quickly create programmatic ad campaigns with our simple
                interface. Hyloq allows you to setup Campaigns, Ad Groups, Ads,
                and assign Geofences and be up-and-running in little to no time.
              </p>
            </div>
            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-semibold text-gray-700 tracking-tight sm:text-3xl sm:leading-9">
                  Targeting
                </h4>
                <p className="mt-3 text-lg leading-7 text-blue-800">
                  Laser Focused Audience Targeting
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Whether running addressable, display, text, native, OTT/CTV,
                  or custom audience ads with Hyloq you can target and capture
                  those devices and audiences with greater accuracy and return
                  unparalleled customer information.
                </p>
                <ul className="mt-10">
                  <FeatureCard
                    top
                    header="Editing"
                    description="Manually draw a fence around a location."
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-pen-tool"
                      >
                        <path d="M12 19l7-7 3 3-7 7-3-3z" />
                        <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z" />
                        <path d="M2 2l7.586 7.586" />
                        <circle cx="11" cy="11" r="2" />
                      </svg>
                    }
                  />
                </ul>
              </div>
              <div className="mt-10 relative lg:mt-0 px-4 lg:px-10">
                <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
                  <ul>
                    <li>
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Auto Campaign
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Upper Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Feather Sound
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-01-01">01/01/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-01-31">01/31/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="border-t border-gray-200">
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Banking Campaign
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Upper Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Pinellas County
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-01-24">01/24/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-02-24">02/24/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="border-t border-gray-200">
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Sports Campaign 2
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Low Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                St. Petersburg
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-02-09">02/09/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-02-31">02/31/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="border-t border-gray-200">
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Sports Campaign 1
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                Disabled
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Middle Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Florida
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-01-01">01/01/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-01-15">01/15/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:px-8 lg:gap-8">
            <div className="relative">
              <h4 className="text-2xl leading-8 font-semibold text-gray-700 tracking-tight sm:text-3xl sm:leading-9">
                Geofence
              </h4>
              <p className="mt-3 text-lg leading-7 text-blue-800">
                Create, Upload, or Search for POI’s
              </p>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                Quickly setup one or multiple geofences, save as templates, and
                assign to campaigns.
              </p>
              <ul className="mt-10">
                <FeatureCard
                  top
                  header="Draw Fences"
                  description="Manually draw a fence around a location."
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-pen-tool"
                    >
                      <path d="M12 19l7-7 3 3-7 7-3-3z" />
                      <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z" />
                      <path d="M2 2l7.586 7.586" />
                      <circle cx="11" cy="11" r="2" />
                    </svg>
                  }
                />
                <FeatureCard
                  header="Search Points of Interest & Create Multiple Fences"
                  description="By using the search tool, you can search for multiple locations (e.g. Chase Banks Florida), and draw a fence around each one with the click of a button."
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                  }
                />
                <FeatureCard
                  header="Upload Addresses"
                  description="Upload an address list, set your parameters, and Hyloq will automatically draw a fence around each location."
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-file-text"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                      <polyline points="14 2 14 8 20 8" />
                      <line x1="16" y1="13" x2="8" y2="13" />
                      <line x1="16" y1="17" x2="8" y2="17" />
                      <polyline points="10 9 9 9 8 9" />
                    </svg>
                  }
                />
              </ul>
            </div>
            <div className="mt-10 -mx-4 relative lg:mt-0 px-4 lg:px-10">
              <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <p className="font-semibold">Create a fence</p>
                  <p className="mt-2 text-gray-500">
                    Enter fence details and then draw a polygon on the map
                  </p>
                </div>
                <hr />
                <form>
                  <div className="px-4 py-5 sm:p-6">
                    <div>
                      <img className="rounded-md" src={DrawFence} />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Fence name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="name"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder="Enter fence name..."
                          value="Feather Sound Golf Course"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="campaign"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Campaign
                      </label>
                      <div className="mt-3 relative rounded-md shadow-sm">
                        <select
                          aria-label="Country"
                          className="form-select relative block w-full rounded-md bg-white focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          <option>Auto Campaign</option>
                          <option>Banking Campaign</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Labels
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="name"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder="Enter fence name..."
                          value="Upper-mid income"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="campaign"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Color
                      </label>
                      <div className="mt-3 relative rounded-md shadow-sm">
                        <select
                          aria-label="Country"
                          className="form-select relative block w-full rounded-md bg-white focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          <option>Red</option>
                          <option>Blue</option>
                          <option>Yellow</option>
                          <option>Green</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-10 block text-right">
                      <span className="inline-flex rounded-md shadow-md">
                        <span className="inline-flex rounded-md shadow-xs">
                          <button
                            disabled
                            className="inline-flex items-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                          >
                            Create
                          </button>
                        </span>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto relative mt-12 lg:grid lg:px-8 lg:gap-8 ">
            <div className="relative lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-semibold text-gray-700 tracking-tight sm:text-3xl sm:leading-9">
                  Reporting
                </h4>
                <p className="mt-3 text-lg leading-7 text-blue-800">
                  Load Templates, Create Your Own, or One-Click Export
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Choose from most used reports or create your own custom
                  reports and save as templates for quick turn-around reports.
                </p>
                <ul className="mt-10">
                  <FeatureCard
                    top
                    header="Create in-depth reports"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file"
                      >
                        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
                        <polyline points="13 2 13 9 20 9" />
                      </svg>
                    }
                  />
                  <FeatureCard
                    header="Review unparalleled customer and device data"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-smartphone"
                      >
                        <rect
                          x="5"
                          y="2"
                          width="14"
                          height="20"
                          rx="2"
                          ry="2"
                        />
                        <line x1="12" y1="18" x2="12.01" y2="18" />
                      </svg>
                    }
                  />
                  <FeatureCard
                    header="Easily export of integrate into your CRM"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-download-cloud"
                      >
                        <polyline points="8 17 12 21 16 17" />
                        <line x1="12" y1="12" x2="12" y2="21" />
                        <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" />
                      </svg>
                    }
                  />
                </ul>
              </div>
              <div className="mt-10 -mx-4 relative lg:mt-0 px-4">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-700">
                    Banking Campaign
                  </h3>
                  <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-4 h-full">
                        <div className="flex items-center h-full">
                          <div className="w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Impressions
                              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                  71,897
                                </div>
                                <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                  <svg
                                    className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="sr-only">Increased by</span>
                                  122
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-4 h-full">
                        <div className="flex items-center h-full">
                          <div className="w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Avg. CTR
                              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                  58.16%
                                </div>
                                <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                  <svg
                                    className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="sr-only">Increased by</span>
                                  5.4%
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-4">
                        <div className="flex items-center h-full">
                          <div className="w-0 flex-1 h-full">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Avg. Click Rate
                              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                  24.57
                                </div>
                                <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-red-600">
                                  <svg
                                    className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="sr-only">Decreased by</span>
                                  3.2%
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-4 h-full">
                      <div className="flex items-center h-full">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                              Conversions
                            </dt>
                            <dd className="flex items-baseline">
                              <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                23,499
                              </div>
                              <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                <svg
                                  className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="sr-only">Increased by</span>
                                643
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-4 h-full">
                      <div className="flex items-center h-full">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                              CPM
                            </dt>
                            <dd className="flex items-baseline">
                              <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                $2.83
                              </div>
                              <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                <svg
                                  className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="sr-only">Increased by</span>
                                $0.12
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-4 h-full">
                      <div className="flex items-center h-full">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                              CPC
                            </dt>
                            <dd className="flex items-baseline">
                              <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                $4.01
                              </div>
                              <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-red-600">
                                <svg
                                  className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="sr-only">Decreased by</span>
                                $0.04
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-20">
            <button
              onClick={handleScrollToTop}
              class="inline-flex text-white bg-red-600 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-red-700 focus:outline-none hover:text-white"
            >
              Let’s Talk
            </button>
          </div>
        </div>
        <div class=" py-10 ">
          <img
            class="w-1/5 mx-auto"
            src="https://imgix.cosmicjs.com/a6c56310-265a-11ee-a19d-717742939f83-Hyloq-Main.png"
          />
        </div>
      </div>
      <div class="mobile block md:hidden">
        <div class="hero relative bg-black overflow-hidden">
          <div class="max-w-7xl mx-auto pt-5 md:pt-0 pb-10 md:pb-0">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover"
                src={HomeHero}
                alt="Consult Home"
              />
            </div>
            <div class="pr-4 pl-1 z-20 uppercase relative nav pb-16">
              <div class="pt-5 relative ">
                <nav
                  class="relative flex items-center justify-between sm:h-10"
                  aria-label="Global"
                >
                  <div class="">
                    <a href="/">
                      <img class="h-12 w-auto" src={Logo} />
                    </a>
                  </div>

                  <div class="">
                    <a
                      href="tel:8135979187"
                      class="ml-14 font-medium text-white hover:underline"
                    >
                      Call Us +1 (813) 597.9187
                    </a>
                  </div>
                </nav>
              </div>
            </div>

            <div class="text-center relative z-10 pb-8 px-5 ">
              <main class="mt-10 mx-auto max-w-7xl">
                <div class="mb-10 mt-5 sm:text-center">
                  <h1 class="uppercase text-4xl tracking-normal font-bold text-white sm:text-5xl md:text-7xl">
                    Hyper Simplicity
                  </h1>
                  <p class="uppercase mt-1 text-base font-light text-white sm:text-lg sm:max-w-xl sm:mx-auto md:text-5xl lg:mx-0">
                    Stand out in the noise
                  </p>
                  <p class="px-5 md:px-0 text-center md:text-left mt-3 text-base text-white sm:mt-5 sm:text-lg max-w-xl mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Unleash the Power of Precision Marketing with Our Advanced
                    Geofencing Software!🌍🎯
                  </p>

                  <p class="px-5 md:px-0 text-center md:text-left mt-3 text-base text-white sm:mt-5 sm:text-lg max-w-xl mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Welcome to the future of marketing! Our cutting-edge
                    geofencing software is meticulously crafted for hyperlocal
                    marketing mastery. Imagine reaching your audience with
                    pinpoint accuracy, right when it matters the most. Our
                    advanced technology lets you draw virtual boundaries around
                    specific areas, allowing you to capture devices and engage
                    potential customers.
                  </p>

                  <div class="hidden mt-14">
                    <a
                      href="/contact-us"
                      class="inline-flex items-center text-blue-900 bg-blue-100 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-blue-900 focus:outline-none hover:text-white"
                    >
                      Contact Sales
                    </a>
                  </div>
                </div>
                <div class="max-w-lg bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-sky-400 via-white p-10 rounded-lg shadow-2xl">
                  {" "}
                  <section id="form">
                    <form
                      method="POST"
                      action="/thank-you"
                      target="_self"
                      id="Hyloq-page-form"
                      name="Hyloq-page-form"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      class=" lg:flex-auto grid-cols-2 grid gap-x-3"
                    >
                      <input
                        type="hidden"
                        name="form-name"
                        value="Hyloq-page-form"
                      />

                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autocomplete="given-name"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autocomplete="given-name"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="mt-6">
                          <input
                            id="job-title"
                            name="job-title"
                            type="text"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Job Title"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            name="company-name"
                            id="company-name"
                            autocomplete="family-name"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>

                      <div>
                        <div class="mt-6">
                          <input
                            type="text"
                            id="company-website"
                            name="company-website"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Company Website"
                          />
                        </div>
                      </div>

                      <div>
                        <div class="mt-6">
                          <input
                            type="email"
                            name="biz-email"
                            id="biz-email"
                            class="block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Company Email"
                          />
                        </div>
                      </div>
                      <div class="col-span-2">
                        <select
                          type="text"
                          name="Monthly-Budget"
                          id="Monthly-Budget"
                          class="mt-6 block w-full rounded-md border-0 px-3.5 py-5 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="" selected disabled hidden>
                            What’s your monthly ad spend?
                          </option>
                          <option value="$5,000 - $10,000">
                            $5,000 - $10,000
                          </option>
                          <option value="$10,000 - $25,000">
                            $10,000 - $25,000
                          </option>
                          <option value=" $25,000 - $50,000+">
                            $25,000 - $50,000+
                          </option>
                          <option value=" NOT SURE HELP ME">
                            NOT SURE HELP ME
                          </option>
                        </select>
                      </div>
                      <div class="col-span-2">
                        <div class="mt-6">
                          <textarea
                            id="message"
                            name="message"
                            rows="4"
                            aria-describedby="message-description"
                            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-700 ring-1 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="How can we help you?    Max 500 characters"
                          ></textarea>
                        </div>
                      </div>
                      <div class="mt-6 col-span-2">
                        <button
                          type="submit"
                          class="block w-full rounded-md bg-red-600 px-3.5 py-4 text-center text-sm font-semibold text-white hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Schedule A Free Consultation
                        </button>
                      </div>
                      <p class="col-span-2 px-10 mt-4 text-xs italic text-center leading-6 text-gray-500">
                        We do not sell your information and will not overwhelm
                        you with emails.
                      </p>
                    </form>
                  </section>
                </div>
              </main>
            </div>
          </div>
          <div class="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5">
            <img
              class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src={HeroMap}
              alt="Geofencing Map"
            />
          </div>
        </div>
        <div class="text-center relative isolate overflow-hidden bg-blue-900">
          <div class="px-6 pt-6 pb-12 sm:px-6 lg:px-8">
            <p class="mx-auto mt-6 max-w-7xl text-base leading-8 text-gray-300">
              Don't miss out on the marketing revolution! Transform your
              outreach with our geofencing software and watch your business
              thrive in the age of hyperlocal marketing. Get started today and
              witness the power of precision marketing at your fingertips! 🔥✨
              #GeofencingMagic #HyperlocalMarketing #MarketingRevolution
            </p>
          </div>
        </div>
        <div class="bg-gradient-to-bl from-blue-100 via-gray-100 to-blue-200 get valuable data">
          <div class="relative overflow-hidden">
            <div class="max-w-7xl mx-auto">
              <div class="px-5 md:px-0 relative pb-8 sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full">
                <main class="mt-14 mx-auto max-w-7xl ">
                  <div class="text-center">
                    <p class="uppercase text-3xl font-semibold text-gray-700 mx-auto md:mt-5 md:text-4xl lg:mx-0">
                      The Real POWER of Hyloq{" "}
                      <span class="block">is in the Pricing</span>
                    </p>
                    <p class="mt-3 text-base text-gray-700 ">
                      Say goodbye to exorbitant hidden advertising costs. Hyloq
                      creates a direct line to multiple ad-exchanges, so you get
                      wholesale pricing for your ad campaigns, based on{" "}
                      <span class="underline">ACTUAL PRICE (*TRUE SPEND).</span>{" "}
                      Simply put, there is no upcharge or middleman between you
                      and the Ad Exchange running the campaign. Hyloq is your
                      direct portal to access TRUE SPEND ADVERTISING. With
                      Hyloq, you can run programmatic ads at a significantly
                      less rate than other agencies. We don’t hide or pass fees
                      onto you; this is your True-Spend™. Find out how we do it,
                      give us a call or fill out our form.
                    </p>
                    <div class="sm:mt-8 sm:flex sm:justify-center lg:justify-start items-center">
                      <img
                        class="hidden md:block w-1/5 "
                        src={MarkerRadiate}
                        alt="Hyloq ID"
                      />
                      <div class="px-10 mt-10 bg-white shadow shadow-lg overflow-hidden rounded-2xl">
                        <ul class="divide-y divide-gray-200">
                          <li class="text-2xl text-red-600 uppercase font-extrabold py-4">
                            Hyloq Id:{" "}
                            <span class="text-black">555-555-555</span>
                          </li>
                          <div class="flex justify-start">
                            <li class="inline py-4 text-gray-700">
                              Dwell:{" "}
                              <span class="text-gray-700 font-bold">
                                {" "}
                                30 mins
                              </span>{" "}
                            </li>
                            <li class="ml-5 py-4 text-gray-700">
                              Enter:{" "}
                              <span class="text-gray-700 font-bold">
                                {" "}
                                3:00 pm
                              </span>{" "}
                            </li>
                            <li class="ml-5 py-4 text-gray-700">
                              Leaves:{" "}
                              <span class="text-gray-700 font-bold">
                                {" "}
                                3:30 pm
                              </span>{" "}
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>

          <div className="pb-10 justify-center items-center flex flex-col ">
            <div className="w-2/3">
              <img
                className="object-contain"
                src={HyloqUser2}
                alt="Hyloq User"
              />
            </div>

            <div className="flex px-4">
              <div className="text-center">
                <h2 className=" mt-8 lg:mt-0 text-3xl leading-9 font-semibold text-gray-700 ">
                  Reach your target audience easily and effectively
                </h2>
                <p className="mt-6 text-lg leading-7 text-gray-500">
                  Hyloq streamlines the process of creating geofences and
                  accessing a large inventory of ad networks to serve ads easily
                  and efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="px-5 Platform md:py-16 bg-white overflow-hidden">
          <div className="mt-12 md:mt-4 relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-4xl sm:leading-10">
                Campaigns
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-lg leading-7 text-blue-800">
                Create, Manage, Evaluate, Repeat.
              </p>
              <p className="mt-4 max-w-3xl mx-auto text-center text-lg leading-7 text-gray-500">
                Quickly create programmatic ad campaigns with our simple
                interface. Hyloq allows you to setup Campaigns, Ad Groups, Ads,
                and assign Geofences and be up-and-running in little to no time.
              </p>
            </div>
            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-semibold text-gray-700 tracking-tight sm:text-3xl sm:leading-9">
                  Targeting
                </h4>
                <p className="mt-3 text-lg leading-7 text-blue-800">
                  Laser Focused Audience Targeting
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Whether running addressable, display, text, native, OTT/CTV,
                  or custom audience ads with Hyloq you can target and capture
                  those devices and audiences with greater accuracy and return
                  unparalleled customer information.
                </p>
                <ul className="mt-10">
                  <FeatureCard
                    top
                    header="Editing"
                    description="Manually draw a fence around a location."
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-pen-tool"
                      >
                        <path d="M12 19l7-7 3 3-7 7-3-3z" />
                        <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z" />
                        <path d="M2 2l7.586 7.586" />
                        <circle cx="11" cy="11" r="2" />
                      </svg>
                    }
                  />
                </ul>
              </div>
              <div className="mt-10 relative ">
                <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
                  <ul>
                    <li>
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Auto Campaign
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Upper Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Feather Sound
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-01-01">01/01/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-01-31">01/31/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="border-t border-gray-200">
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Banking Campaign
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Upper Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Pinellas County
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-01-24">01/24/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-02-24">02/24/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="border-t border-gray-200">
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Sports Campaign 2
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Low Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                St. Petersburg
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-02-09">02/09/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-02-31">02/31/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="border-t border-gray-200">
                      <div className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="text-sm leading-5 font-medium text-blue-600 truncate">
                              Sports Campaign 1
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                Disabled
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                Middle Income
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Florida
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span>
                                <time datetime="2020-01-01">01/01/2020</time>
                                &nbsp;-&nbsp;
                                <time datetime="2020-01-15">01/15/2020</time>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:px-8 lg:gap-8">
            <div className="relative">
              <h4 className="text-2xl leading-8 font-semibold text-gray-700 tracking-tight sm:text-3xl sm:leading-9">
                Geofence
              </h4>
              <p className="mt-3 text-lg leading-7 text-blue-800">
                Create, Upload, or Search for POI’s
              </p>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                Quickly setup one or multiple geofences, save as templates, and
                assign to campaigns.
              </p>
              <ul className="mt-10">
                <FeatureCard
                  top
                  header="Draw Fences"
                  description="Manually draw a fence around a location."
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-pen-tool"
                    >
                      <path d="M12 19l7-7 3 3-7 7-3-3z" />
                      <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z" />
                      <path d="M2 2l7.586 7.586" />
                      <circle cx="11" cy="11" r="2" />
                    </svg>
                  }
                />
                <FeatureCard
                  header="Search Points of Interest & Create Multiple Fences"
                  description="By using the search tool, you can search for multiple locations (e.g. Chase Banks Florida), and draw a fence around each one with the click of a button."
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                  }
                />
                <FeatureCard
                  header="Upload Addresses"
                  description="Upload an address list, set your parameters, and Hyloq will automatically draw a fence around each location."
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-file-text"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                      <polyline points="14 2 14 8 20 8" />
                      <line x1="16" y1="13" x2="8" y2="13" />
                      <line x1="16" y1="17" x2="8" y2="17" />
                      <polyline points="10 9 9 9 8 9" />
                    </svg>
                  }
                />
              </ul>
            </div>
            <div className="mt-10 -mx-4 relative lg:mt-0 px-4 lg:px-10">
              <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <p className="font-semibold">Create a fence</p>
                  <p className="mt-2 text-gray-500">
                    Enter fence details and then draw a polygon on the map
                  </p>
                </div>
                <hr />
                <form>
                  <div className="px-4 py-5 sm:p-6">
                    <div>
                      <img className="rounded-md" src={DrawFence} />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Fence name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="name"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder="Enter fence name..."
                          value="Feather Sound Golf Course"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="campaign"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Campaign
                      </label>
                      <div className="mt-3 relative rounded-md shadow-sm">
                        <select
                          aria-label="Country"
                          className="form-select relative block w-full rounded-md bg-white focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          <option>Auto Campaign</option>
                          <option>Banking Campaign</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Labels
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="name"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder="Enter fence name..."
                          value="Upper-mid income"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="campaign"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Color
                      </label>
                      <div className="mt-3 relative rounded-md shadow-sm">
                        <select
                          aria-label="Country"
                          className="form-select relative block w-full rounded-md bg-white focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          <option>Red</option>
                          <option>Blue</option>
                          <option>Yellow</option>
                          <option>Green</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-10 block text-right">
                      <span className="inline-flex rounded-md shadow-md">
                        <span className="inline-flex rounded-md shadow-xs">
                          <button
                            disabled
                            className="inline-flex items-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                          >
                            Create
                          </button>
                        </span>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto relative mt-12 lg:grid lg:px-8 lg:gap-8 ">
            <div className="relative lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-semibold text-gray-700 tracking-tight sm:text-3xl sm:leading-9">
                  Reporting
                </h4>
                <p className="mt-3 text-lg leading-7 text-blue-800">
                  Load Templates, Create Your Own, or One-Click Export
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Choose from most used reports or create your own custom
                  reports and save as templates for quick turn-around reports.
                </p>
                <ul className="mt-10">
                  <FeatureCard
                    top
                    header="Create in-depth reports"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file"
                      >
                        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
                        <polyline points="13 2 13 9 20 9" />
                      </svg>
                    }
                  />
                  <FeatureCard
                    header="Review unparalleled customer and device data"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-smartphone"
                      >
                        <rect
                          x="5"
                          y="2"
                          width="14"
                          height="20"
                          rx="2"
                          ry="2"
                        />
                        <line x1="12" y1="18" x2="12.01" y2="18" />
                      </svg>
                    }
                  />
                  <FeatureCard
                    header="Easily export of integrate into your CRM"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-download-cloud"
                      >
                        <polyline points="8 17 12 21 16 17" />
                        <line x1="12" y1="12" x2="12" y2="21" />
                        <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" />
                      </svg>
                    }
                  />
                </ul>
              </div>
              <div className="mt-10 -mx-4 relative lg:mt-0 px-4">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-700">
                    Banking Campaign
                  </h3>
                  <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-4 h-full">
                        <div className="flex items-center h-full">
                          <div className="w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Impressions
                              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                  71,897
                                </div>
                                <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                  <svg
                                    className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="sr-only">Increased by</span>
                                  122
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-4 h-full">
                        <div className="flex items-center h-full">
                          <div className="w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Avg. CTR
                              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                  58.16%
                                </div>
                                <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                  <svg
                                    className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="sr-only">Increased by</span>
                                  5.4%
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-4">
                        <div className="flex items-center h-full">
                          <div className="w-0 flex-1 h-full">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Avg. Click Rate
                              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                  24.57
                                </div>
                                <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-red-600">
                                  <svg
                                    className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="sr-only">Decreased by</span>
                                  3.2%
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-4 h-full">
                      <div className="flex items-center h-full">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                              Conversions
                            </dt>
                            <dd className="flex items-baseline">
                              <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                23,499
                              </div>
                              <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                <svg
                                  className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="sr-only">Increased by</span>
                                643
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-4 h-full">
                      <div className="flex items-center h-full">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                              CPM
                            </dt>
                            <dd className="flex items-baseline">
                              <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                $2.83
                              </div>
                              <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-green-600">
                                <svg
                                  className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="sr-only">Increased by</span>
                                $0.12
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-4 h-full">
                      <div className="flex items-center h-full">
                        <div className="w-0 flex-1">
                          <dl>
                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                              CPC
                            </dt>
                            <dd className="flex items-baseline">
                              <div className="text-lg xl:text-2xl leading-8 font-semibold text-gray-700">
                                $4.01
                              </div>
                              <div className="ml-2 flex items-baseline text-xs xl:text-sm leading-5 font-semibold text-red-600">
                                <svg
                                  className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="sr-only">Decreased by</span>
                                $0.04
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-20">
            <button
              onClick={handleScrollToTop}
              class="inline-flex text-white bg-red-600 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-red-700 focus:outline-none hover:text-white"
            >
              Let’s Talk
            </button>
          </div>
        </div>
        <div class=" py-10 ">
          <img
            class="w-1/5 mx-auto"
            src="https://imgix.cosmicjs.com/a6c56310-265a-11ee-a19d-717742939f83-Hyloq-Main.png"
          />
        </div>
      </div>
    </>
  );
};

export default hyloq;
